// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slide-right {
    opacity: 0;
    transform: translateX(-200px);
    transition: opacity 2s, transform 2s;
}

.slide-left {
    opacity: 0;
    transform: translateX(200px);
    transition: opacity 2s, transform 2s;
}

.slide-right.visible, .slide-left.visible {
    opacity: 1;
    transform: translateX(0);
}`, "",{"version":3,"sources":["webpack://./src/Components/ScrollAnimator/ScrollAnimation.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,6BAA6B;IAC7B,oCAAoC;AACxC;;AAEA;IACI,UAAU;IACV,4BAA4B;IAC5B,oCAAoC;AACxC;;AAEA;IACI,UAAU;IACV,wBAAwB;AAC5B","sourcesContent":[".slide-right {\n    opacity: 0;\n    transform: translateX(-200px);\n    transition: opacity 2s, transform 2s;\n}\n\n.slide-left {\n    opacity: 0;\n    transform: translateX(200px);\n    transition: opacity 2s, transform 2s;\n}\n\n.slide-right.visible, .slide-left.visible {\n    opacity: 1;\n    transform: translateX(0);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
