import React from "react";

import useMouseShadow from "./useMouseShadow";
import colors from "../share/colors";

function SideLineDiv({children, side = "left"}) {
  const [shadow, shadowRef] = useMouseShadow("boxShadow");

  return (
    <div className="title-with-sideline" style={{ display: "flex", flexDirection: "row", height: "min-content" }}>
      {side == "left" && <div className="sideline left" style={{ backgroundColor: colors.secondary, width: "7px", ...shadow }} ref={shadowRef}/>}
      {children}
      {side == "right" && <div className="sideline right" style={{ backgroundColor: colors.secondary, width: "7px", ...shadow }} ref={shadowRef}/>}
    </div>
  );
}

export default SideLineDiv;