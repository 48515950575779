import React from "react";

import colors from "../share/colors";

function LightBulb({children, size, onMouseEnter = () => {}, onMouseLeave = () => {}, onClick = () => {}}) {
    return (
        <div style={{ position: 'relative', width: size }} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick}>
            <div style={{ paddingTop: '100%' }}/>
            <div style={{ position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        backgroundColor: colors.light,
                        borderRadius: '50%',
                        boxSizing: 'border-box',
                        padding: "7px",
                        boxShadow: "0 0 23px 10px rgba(255,255,255,0.29)"
                        }}>
                {children}
            </div>
        </div>
    );
}

export default LightBulb;