import React, { useEffect, useState, useRef, useContext } from "react";
import CustomCursorContext from "./CustomCursorContext";

function CustomCursor() {
  const cursorRef = useRef(null);
  const value = useContext(CustomCursorContext);
  const isFlatCursor = value.isFlatCursor;
  const setIsFlatCursor = value.setIsFlatCursor;

  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const setFromEvent = e => setPosition({ x: e.clientX, y: e.clientY });
    window.addEventListener("mousemove", setFromEvent);

    return () => {
      window.removeEventListener("mousemove", setFromEvent);
    };
  }, []);

  useEffect(() => {
    if (isFlatCursor) {
      cursorRef.current.classList.add("flat");
    } else {
      cursorRef.current.classList.remove("flat");
    }
  }, [isFlatCursor]);

  const cursorFollowerStyle = {
    position: "absolute",
    top: position.y + document.documentElement.scrollTop,
    left: position.x,
    width: "40px",
    height: "40px",
    transform: "translate(-50%, -50%)",
    borderRadius: "50%",
    backgroundColor: "rgba(255,255,255,0.2)",
    pointerEvents: "none",
    zIndex: 1,
    // transition: 'left 0.1s ease, top 0.1s ease', // Adjusted transition duration
  };

  return (
    <>
      <div className="cursor"
        ref={cursorRef}
        style={{
          top: position.y + document.documentElement.scrollTop,
          left: position.x}} className="customCursor" />
      <div className="cursor-follower" style={cursorFollowerStyle}/>
    </>
  );
}

export default CustomCursor;
