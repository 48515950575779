import React, { useState, useEffect, useRef } from 'react';
import './ScrollAnimation.css';

const ScrollAnimator = ({ children , className = "slide-right"}) => {
  const [isVisible, setIsVisible] = useState(false);
  const slider_ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
        if (!slider_ref.current) {
            return;
        }

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const elementOffset = slider_ref.current.offsetTop;
        const windowHeight = window.innerHeight;

        if (scrollTop > elementOffset - windowHeight && !isVisible) {
            setIsVisible(true);
        } else if (scrollTop <= elementOffset - windowHeight && isVisible) {
            setIsVisible(false);
        }
    };

    handleScroll(); // Initial check when the component mounts

    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, [isVisible, slider_ref]);

  return (
    <div className={`${className} ${isVisible ? 'visible' : ''}`} ref={slider_ref}>
      {children}
    </div>
  );
};

export default ScrollAnimator;
