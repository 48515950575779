import React, { useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

import useMouseShadow from './useMouseShadow';
import colors from '../share/colors';

import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import BurgerMenu from './BurgerMenu';

function NavbarLink({text, anchor = ""})
{
    const [style, shadowRef] = useMouseShadow();

    return (
        <div ref={shadowRef} style={style}>
            <a href={"#" + anchor} style={{color: colors.light, textDecoration: "none", padding: "5px 10px 5px 10px", transition: "color 0.2s ease"}} onMouseEnter={(e) => {
            }}>{text}</a>
        </div>
    )
}

function SocialMedia({style})
{
    return (
        <Row style={{width: "100", flexDirection: "row-reverse"}}>
            <Col xl={2} xs={6}>
                <FontAwesomeIcon icon={faLinkedin} onClick={() => window.open("https://www.linkedin.com/in/axel-rohee")} style={{cursor: "pointer"}}/>
            </Col>
            <Col xl={2} xs={6}>
                <FontAwesomeIcon icon={faGithub} onClick={() => window.open("https://github.com/RoheeAxel")} style={{cursor: "pointer"}}/>
            </Col>
        </Row>
    )
}

function NavBarContent({setIsOpen = () => {}})
{
    return (
        <>
            <Col xs={12} xl="auto" onClick={() => setIsOpen(false)}>
                <NavbarLink text="ABOUT ME" anchor="about"/>
            </Col>
            <Col xs={12} xl="auto" onClick={() => setIsOpen(false)}>
                <NavbarLink text="MY PROJECTS" anchor='projects'/>
            </Col>
            <Col xs={12} xl="auto" onClick={() => setIsOpen(false)}>
                <NavbarLink text="CONTACT ME" anchor='contact'/>
            </Col>
            <Col xl={4} />
            <Col xs={12} xl={2}>
                <SocialMedia/>
            </Col>
        </>
    )
}

function Navbar() {
    const navbarRef = useRef(null);
    const [screenSize, setScreenSize] = useState(window.innerWidth);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    function handleResize()
    {
        setScreenSize(window.innerWidth);
    }

    useEffect(() => {
        if (navbarRef.current === null)
        {
            return;
        }
        const navbar = navbarRef.current;
        const sticky = navbar.offsetTop - 2;

        function scrollFunction()
        {
            if (window.pageYOffset > sticky)
            {
                navbar.classList.add("sticky");
            }
            else
            {
                navbar.classList.remove("sticky");
            }
        }

        window.addEventListener("scroll", scrollFunction);
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("scroll", scrollFunction);
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            {screenSize > 1200 && (
            <Row style={{width: "100%", alignItems: "center"}} className='navbar' ref={navbarRef}>
                <NavBarContent/>
            </Row>
            )}
            {screenSize <= 1200 && (
                <>
                    <div style={{display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "center"}}
                          className={isMenuOpen ? "navbar sticky open" : "navbar sticky"}>
                        <BurgerMenu isOpen={isMenuOpen} setIsOpen={setIsMenuOpen}/>
                        {isMenuOpen && (
                            <Row style={{width: "100%", alignItems: "center"}} className='navbar'>
                                <NavBarContent setIsOpen={() => {setIsMenuOpen(false); document.getElementById("burgerMenu").classList.remove("open")}}/>
                            </Row>
                        )
                        }
                    </div>
                </>
            )
            }
        </>
    );
}

export default Navbar;