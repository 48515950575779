import React, { useState } from 'react';

import './burgerMenu.css';

function BurgerMenu({isOpen, setIsOpen}) {

    function handleOpen()
    {
        setIsOpen(!isOpen);
        if (isOpen)
        {
            document.getElementById("burgerMenu").classList.remove("open");
        } else {
            document.getElementById("burgerMenu").classList.add("open");
        }
    }

    return (
        <div style={{display: "flex", flexDirection: "row-reverse", width: "100%", padding: "1rem"}}>
            <div id="burgerMenu" className="burger" style={{width:"40px"}} onClick={() => handleOpen()}>
                <div className="line"/>
                <div className="line"/>
                <div className="line"/>
            </div>
        </div>
    );
}

export default BurgerMenu;