// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.burger .line {
  width: 100%;
  height: 3px;
  border-radius: 3px;
  background-color: #ffffff;
  margin: 1rem 0;
  transition: .5s ease-in-out;
}

.burger .line {
  transform-origin: left center;
}

.burger.open .line:nth-child(1) {
  transform: rotate(45deg);
  width: 134%;
}

.burger.open .line:nth-child(2) {
  opacity: 0;
  transform: translate(20px);
}

.burger.open .line:nth-child(3) {
  transform: rotate(-45deg);
  width: 134%;
}`, "",{"version":3,"sources":["webpack://./src/Components/burgerMenu.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;EACX,kBAAkB;EAClB,yBAAyB;EACzB,cAAc;EACd,2BAA2B;AAC7B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,wBAAwB;EACxB,WAAW;AACb;;AAEA;EACE,UAAU;EACV,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb","sourcesContent":[".burger .line {\n  width: 100%;\n  height: 3px;\n  border-radius: 3px;\n  background-color: #ffffff;\n  margin: 1rem 0;\n  transition: .5s ease-in-out;\n}\n\n.burger .line {\n  transform-origin: left center;\n}\n\n.burger.open .line:nth-child(1) {\n  transform: rotate(45deg);\n  width: 134%;\n}\n\n.burger.open .line:nth-child(2) {\n  opacity: 0;\n  transform: translate(20px);\n}\n\n.burger.open .line:nth-child(3) {\n  transform: rotate(-45deg);\n  width: 134%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
