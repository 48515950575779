import React from "react";

import colors from "../share/colors";

function TimeLine({elements}) {
    const style = {
        width: "50%",
        fontFamily: "NATS",
        fontSize: "22px",
        color: colors.light,
        marginTop: "-0.5rem",
        fontWeight: "normal"
    };
    return (
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%"}}>
            {elements.map((element, index) => (
               <div key={index} style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start", width: "100%"}}>
                    <div style={{textAlign: "right", paddingRight: "2rem", ...style}}>
                        {index % 2 === 0 ? element : ""}
                    </div>
                    {index !== elements.length - 1 && (
                        <div style={{backgroundColor: colors.light, width: "0.3rem", height: "6rem", alignSelf: "center"}}/>
                    )}
                <div style={{borderRadius:"50px", height:"20px", width:"20px", backgroundColor: colors.light, zIndex:"1", margin: "-2px -11px"}}/>
                    <div style={{textAlign: "left", paddingLeft: "2rem", ...style}}>
                        {index % 2 === 1 ? element : ""}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default TimeLine;