import { useState, useRef, useEffect } from 'react';

const useMouseShadow = (shadowType = 'textShadow') => {
  const [shadow, setShadow] = useState({ x: 0, y: 0 });
  const textRef = useRef();

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (!textRef.current) {
        return;
      }
      const textRect = textRef.current.getBoundingClientRect();
      const x = (textRect.left + textRect.right) / 2;
      const y = (textRect.top + textRect.bottom) / 2;
      setShadow({ x: (x - event.clientX) / 25, y: (y - event.clientY) / 25 });
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const style = {
    [shadowType]: `${shadow.x}px ${shadow.y}px 3px rgba(0,0,0,0.3)`
  };

  return [style, textRef];
};

export default useMouseShadow;