import React, { useEffect, useState } from 'react';
import './App.css';

import SideLineDiv from './Components/SideLineDiv';
import useMouseShadow from './Components/useMouseShadow';

import LightBulb from './Components/LighteBulb';
import Image from './Components/Image';
import CustomCursor from './Components/CustomCursor';
import Navbar from './Components/Navbar';
import TimeLine from './Components/TimeLine';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import colors from './share/colors';
import Projects from './Projects';

import CustomCursorContext from './Components/CustomCursorContext';

import ScrollAnimator from './Components/ScrollAnimator/ScrollAnimator';

import StarField from './Components/Starfield';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';


function App() {
  const [style, shadowRef] = useMouseShadow();
  const [style2, shadowRef2] = useMouseShadow();
  const [style3, shadowRef3] = useMouseShadow();
  const [isFlatCursor, setIsFlatCursor] = useState(false);

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/projects/")
    .then(res => res.json())
    .then(data => {
      setProjects(data.projects);
    });
  }, []);


  return (
    <div style={{background: "radial-gradient(circle at 80% 20%, rgba(115,20,237,1) 0%, rgba(29,17,40,1) 65%)", height: "auto", overflowY: "hidden", overflowX: "hidden"}}>
      <CustomCursorContext.Provider value={{isFlatCursor, setIsFlatCursor}}>
        <div style={{ height: "100vh", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center"}}>
          <Row style={{width: "100%"}} className='text-spacer'>
            <Col md={0} lg={2}/>
            <Col md={12} lg={5}>
              <ScrollAnimator>
              <SideLineDiv side='right'>
                <h1 ref={shadowRef} align="right" style={style}>
                  AXEL<br/>ROHEE
                </h1>
              </SideLineDiv>
              </ScrollAnimator>
            </Col>
            <Col md={8} lg={3} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <LightBulb size="100%">
                <Image src="portrait.webp" alt="Axel Rohee" rounded={true} width={220} widthMobile={165} />
              </LightBulb>
            </Col>
          </Row>
          <Navbar />
        </div>
        <div style={{height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width:"100%"}} id="about">
          <Row style={{width: "100%", paddingTop: "5rem"}}>
            <Col md={0} xl={2}/>
            <Col md={12} xl={4}>
              <ScrollAnimator>
                <SideLineDiv side='left'>
                  <h2 ref={shadowRef2} align="left" style={style2}>
                    ABOUT ME
                  </h2>
                </SideLineDiv>
              </ScrollAnimator>
              <ScrollAnimator>
                <p style={{color: colors.light, textAlign: "left", margin: "2rem 2rem"}} onMouseEnter={() => {setIsFlatCursor(true)}} onMouseLeave={() => {setIsFlatCursor(false)}}>
                Hi, I'm Axel Rohee. I'm a 20-year-old freelance developer from France, currently in my third year at EPITECH Rennes. I'm passionate about programming, Artificial Intelligence, Quantum computation, and science in general, and I love astronomy. I've been developing since I was 14 years old, gaining experience with various languages and frameworks. Currently, I'm studying at Korea University, the second-best university in Korea.My current side project is a Quantum computation simulator in python.
                </p>
              </ScrollAnimator>
            </Col>
            <Col md={12} xl={6}>
              <div className='spacer'/>
              <ScrollAnimator className='slide-left'>
              <TimeLine elements={["Lycée Notre Dame Avranches (2018-2021)",
                              "Stage Miclon informatique(juin 2019)",
                              "EPITECH Rennes (2021-2026)",
                              "Stage Altilog (Hiver 2022)",
                              "Stage Enensys (Printemps 2024)",
                              "Korea Univeristy (2024-2025)"
                              ]}/>
              </ScrollAnimator>
            </Col>
          </Row>
        </div>
        <div style={{height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width:"100%"}} id="projects">
            <Row style={{width: "100%", flexDirection: "row-reverse"}}>
              <Col md={0} lg={1}/>
              <Col md={12} lg={4} style={{marginTop:"10rem"}}>
                <ScrollAnimator className='slide-left'>
                  <SideLineDiv side='right'>
                    <h2 ref={shadowRef3} align="right" style={style3}>
                      MY PROJECTS
                    </h2>
                  </SideLineDiv>
                </ScrollAnimator>
              </Col>
              <Col md={12} lg={5}>
                  <div style={{width: "65%"}}>
                    <Image src="spring.webp" alt="A image representing a spring" rounded={false} style={{ animation: "float 6s ease-in-out infinite"}}/>
                  </div>
              </Col>
            </Row>
            {projects.length > 0 && projects.map((project, index) => {
              const orientation = index % 2 === 0 ? "rtl" : "ltr";
              return <Projects name={project.name} orientation={orientation} key={index} setIsFlatCursor={setIsFlatCursor} />
              }
            )}
        </div>
        <div style={{height: "85vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width:"100%"}} id="contact">
        {/* <div style={{height: "80vh", width: "100%", backgroundColor: "rgba(29,17,40,1)"}}> */}
          {/* <StarField /> */}
          <div style={{height: "100%", top:"0", width:"100%"}}>
            <Row style={{width: "100%"}} className='text-spacer'>
            <Col md={0} xl={2}/>
            <Col md={12} xl={4}>
                <ScrollAnimator>
                  <SideLineDiv side='left'>
                    <h2 align="left">
                      FOLLOW ME !
                    </h2>
                  </SideLineDiv>
                </ScrollAnimator>
                <ScrollAnimator>
                  <p style={{color: colors.light, textAlign: "left", margin: "2rem 2rem"}} onMouseEnter={() => {setIsFlatCursor(true)}} onMouseLeave={() => {setIsFlatCursor(false)}}>
                    You can follow me on my social media to stay up to date with my projects and my life. I'm always open to new opportunities and collaborations, so don't hesitate to contact me!
                  </p>
                  <Row style={{display: "flex", justifyContent: "center", alignItems: "center", color: colors.light, height: "2%"}}>
                    <Col xl={6} xs={6} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <FontAwesomeIcon icon={faLinkedin} onClick={() => window.open("https://www.linkedin.com/in/axel-rohee")} style={{cursor: "pointer", height: "2em"}}/>
                    </Col>
                    <Col xl={6} xs={6} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <FontAwesomeIcon icon={faGithub} onClick={() => window.open("https://github.com/RoheeAxel")} style={{cursor: "pointer", height: "2em"}}/>
                    </Col>
                  </Row>

                </ScrollAnimator>
              </Col>
              </Row>
          </div>
        </div>
        <CustomCursor />
      </CustomCursorContext.Provider>
    </div>
  );
}

export default App;