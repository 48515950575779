import React, { useEffect, useState } from 'react';

import LightBulb from './Components/LighteBulb';
import Image from './Components/Image';
import colors from './share/colors';
import SideLineDiv from './Components/SideLineDiv';
import useMouseShadow from './Components/useMouseShadow';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ScrollAnimator from './Components/ScrollAnimator/ScrollAnimator';

function Projects({name, orientation = "ltr", setIsFlatCursor, prePath = ""})
{
    const orientationStyle = orientation === "ltr" ? "row" : "row-reverse";
    const scrollAnimationOrientation = orientation === "ltr" ? "slide-right" : "slide-left";

    const [project, setProject] = useState({});
    const [onMouseEnter, setOnMouseEnter] = useState(false);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL +  "/projects/" + name)
        .then(res => res.json())
        .then(data => {
          setProject(data);
        });
      }, []);


    return (
        <>
            <Row style={{width: "100%", flexDirection: orientationStyle, marginTop: "1rem"}}>
                <Col md={0} xl={1}/>
                <Col md={12} xl={4}>
                <div style={{marginLeft: "2rem", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} className='project-container'>
                    <LightBulb size="345px" > {/* onClick={() => {setOnMouseEnter(true)}} onMouseLeave={() => {setOnMouseEnter(false)}} */}
                        {project.image && <Image src={prePath + "projects/" + project.image} alt={name} rounded={true} mouseState={onMouseEnter}/>}
                    </LightBulb>
                    <h3 style={{color: colors.light, textAlign: "center"}}>
                        {name}
                    </h3>
                </div>
                </Col>
                <Col md={12} xl={5}>
                    <ScrollAnimator className={scrollAnimationOrientation}>
                        <p style={{color: colors.light, textAlign: "left", margin: "5rem 2rem"}} onMouseEnter={() => {setIsFlatCursor(true)}} onMouseLeave={() => {setIsFlatCursor(false)}}>
                            {project.text_fr && project.text_fr}
                        </p>
                        <Row style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            {project.technologies && project.technologies.map((tech, index) => {
                            return (
                                <Col md={2} xl={1} xs={2} key={index}>
                                    <Image src={prePath + "technologies/" + tech + ".png"} alt={tech} rounded={false} />
                                </Col>
                                )
                            })}
                        </Row>
                    </ScrollAnimator>
                </Col>
            </Row>
        </>
    );
}

export default Projects;