import React, { useRef, useEffect, useState } from 'react';

function Image({ src, alt = 'Image', mouseState = false, rounded = true, width = 165, widthMobile = 165, style = {} }) {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const toUseWidth = window.innerWidth > 1200 ? width : widthMobile;
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(ref.current); // Cleanup observer after it intersects
        }
      },
      {
        rootMargin: '100px', // Adjust this margin to load before the element comes into view
      }
    );

    observer.observe(ref.current); // Start observing the element

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current); // Cleanup observer on component unmount
      }
    };
  }, []); // Run the effect only once when the component mounts

  const backgroundImageStyle = isVisible
    ? { backgroundImage: `url(${src})` }
    : {}; // Background image is set when visible

  useEffect(() => {
    if (mouseState) {
      //scroll on y axis so the top of the image is in the middle of the screen
      ref.current.style.transition = "clip-path 3s ease-in-out 0.5s, width 1s ease-in-out 1s, height 1s ease-in-out 1s, transform 1s ease-in-out";
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      let imageX = ref.current.getBoundingClientRect().x + ref.current.getBoundingClientRect().width / 2;
      let centerX = window.innerWidth / 2;
      let translateX = centerX - imageX;
      ref.current.style.transform = 'translateX(' + translateX + 'px) scale(6)';
      ref.current.style.clipPath = 'circle(1000px at center)';
      ref.current.style.zIndex = 1;
      //disable scroll
      document.body.style.overflow = 'hidden';
    } else {
      if (rounded !== false) {
        ref.current.style.transition = "clip-path 0.5s ease-in-out, width 1s ease-in-out 1s, height 1s ease-in-out 1s, transform 1.5s ease-in-out";
        ref.current.style.transform = 'translateX(0px)';
        ref.current.style.clipPath = 'circle(' + toUseWidth + 'px at center)';
        ref.current.style.zIndex = 0;
        //enable scroll
        document.body.style.overflow = 'auto';
      }
    }
  }, [mouseState, rounded, width]);

  return (
    <div
      ref={ref}
      style={{
        ...backgroundImageStyle,
        backgroundSize: 'cover',
        imageRendering: 'crisp-edges',
        backgroundPosition: 'center',
        aspectRatio: '1/1',
        position: 'relative',
        // borderRadius: rounded ? '50%' : '0%',
        ...style,
      }}
      className= {rounded === true ? "rounded" : ""}
    />
  );
}

export default Image;
